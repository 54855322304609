import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials" className="py-5">
      <div className="container">
        <div className="section-title text-center mb-4">
          <h2>What our clients say</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4 mb-4">
                  <div className="card h-100 border-0 shadow-sm">
                    <div className="card-header text-center bg-white border-0">
                      <img
                        src={d.img}
                        alt={d.name}
                        className="testimonial-logo img-fluid"
                        style={{ maxHeight: "80px" }}
                      />
                    </div>
                    <hr className="my-2" /> {/* Small line between logo and text */}
                    <div className="card-body">
                      <p className="card-text text-muted">"{d.text}"</p>
                    </div>
                    <div className="card-footer text-center bg-white border-0">
                      <span className="testimonial-meta font-weight-bold">- {d.name}</span>
                    </div>
                  </div>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
